import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import Header from './components/Header.js';
import Footer from './components/Footer.js';

const faqs = [
    {
        question: "How does Stole.ai work?",
        answer:
            "Stole.ai uses Artificial Intelligence to generate reports that identify overlap in content.  You can supply plain text content and/or upload documents that support your topic.  We then use that content and the instructions that you provide to identify this overlap.",
    },
    {
        question: "What types of documents can I upload that can be used as input to generating reports on Stole.ai?",
        answer:
            "Stole.ai supports PDF, Microsoft Word and simple text documents.  You can also upload transcripts from web presentation platforms such as Zoom and WebEx.",
    },
    {
        question: "How long does it take to generate a report using Stole.ai?",
        answer:
            "In many cases, Stole.ai will generate a report and email it to you within an hour after submitting the request if you are not uploading documents to be used as input.  It takes a bit longer to generate reports when uploaded documents are used because of the need to process each and every page, but this is still likely to complete within an hour.",
    },
    {
        question: "I have a question that is not answered here.  How do I get in touch?",
        answer:
            "There's link to a contact form at the bottom of this page.",
    },
]

export default function FAQ() {
    return (
        <div className="bg-white">
            <Header />
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-blue-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-blue-600">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-blue-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-blue-600">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-blue-500">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
            <Footer />
        </div>
    )
}

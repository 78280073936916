import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useParams, useLocation } from 'react-router-dom';

import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Navigation from './Navigation.js'
import makeApiRequest from './util/diffute.js';
import ApiError from './errors/pptx';

import Steps from './components/Steps.js'
import Templates from './components/Templates.js'
import Confirmation from './components/ConfirmationComponent.js'

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'

export default function CheckoutCompleted() {
    const navigate = useNavigate(); // Get the navigate function
    const location = useLocation();
    const { deck_id } = useParams();
    const [deck, setDeck] = useState(null);  // State to hold the fetched data
    const [credits, setCredits] = useState(null);  // State to hold the fetched data
    const [error, setError] = useState(null); // New state variable for error handling

    const getQueryParams = (search) => {
        return Object.fromEntries(new URLSearchParams(search));
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = getQueryParams(location.search);  // Extract query params from the URL

                const dataToSend = {
                    // ...jsonPayload,
                    ...queryParams
                };

                const csrfResponse = await makeApiRequest('csrf', null, 'GET');
                const csrfToken = csrfResponse.csrf_token;

                // Prepare headers with CSRF token
                const extraHeaders = {
                    'x-csrf-token': csrfToken
                };

                const responseData = await makeApiRequest('confirmationnew/' + deck_id, navigate, 'POST', dataToSend, false, 4, queryParams, extraHeaders);

                if (responseData) {
                    setDeck(responseData.deck);
                    setCredits(responseData.user.credits);
                    setError(null);
                    localStorage.removeItem('formData');
                    // const cleanURL = window.location.protocol + "//" + window.location.host + window.location.pathname;
                    // window.history.replaceState({}, document.title, cleanURL);
                } else {
                    setError("API response is null or undefined.");
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    const status = error.status;
                    if (status >= 400 && status < 500) {
                        setError(error.message || 'Client error');
                    } else if (status >= 500) {
                        setError(error.message || 'Server error');
                    } else {
                        setError('An unexpected error occurred');
                    }
                    console.error(`Error ${status}:`, error.message);
                } else {
                    setError('An unexpected error occurred');
                    console.error('Unexpected error:', error);
                }
            }
        };

        fetchData();
    }, [location.search, deck_id]);  // Dependency array updated to include variables used inside the useEffect


    return (

        <div>
            <Navigation />
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                <div className="mx-auto max-w-3xl">
                    <div>
                        <div>
                            {deck ? <Confirmation urls={deck.urls} uploadedFiles={deck.uploaded_files} customProfile={deck.custom_profile} selectedSpeakerName={deck.speaker_name} completeNotes={deck.complete_notes} selectedTemplateName={deck.pptx_template} description={deck.description} deck_status={deck.deck_status} email_address={deck.email_address} credit={credits} />

                                // : "Crunching Numbers..."
                                : <div style={{
                                    animation: 'rotate 1s infinite',
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1000 // ensure it's on top of everything else
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgba(37, 99, 235, 1)" className="w-36 h-36" style={{ transformOrigin: 'center' }}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                                    </svg>
                                </div>

                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}
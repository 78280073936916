import { useState } from 'react';
import { Link } from 'react-router-dom';

function formatDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000); // Convert Unix time to JS time

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0'); // Add leading zero if needed
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero if needed

    return `${month}-${day}-${year} ${hours}:${minutes}`;
}

function getDeckStatus(deck) {
    let deck_status = "";
    if (deck.deck_status === "stub") {
        deck_status = "Not Submitted";
    }
    else if (deck.deck_status === "submitted") {
        deck_status = "Draft";
    }
    else if (deck.deck_status === "processing") {
        deck_status = "Processing";
    }
    else if (deck.deck_status === "completed") {
        deck_status = "Completed";
    }
    else {
        deck_status = deck.deck_status
    }

    return deck_status
}

export default function ListOfDecks({ decks, handleSubmit }) {
    const sortedDecks = decks.sort((a, b) => b.date - a.date);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmission = async (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Start submitting
        try {
            await handleSubmit(event); // Replace with your actual submission logic
            // handle success if needed
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setIsSubmitting(false); // Stop submitting whether success or error
        }
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="mt-8 text-base font-semibold leading-6 text-gray-900">Plagiarism Reports</h1>
                    {/* <p className="mt-2 text-sm text-gray-700">
                        A list of your presentations.
                    </p> */}
                </div>
                <div className="mt-8 sm:ml-16 sm:mt-0 sm:flex-none">
                    {/* <button
                        onClick={handleSubmit}
                        type="button"
                        className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                        New Presentation
                    </button> */}

                    <button
                        onClick={(event) => handleSubmission(event)}
                        type="button"
                        className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex items-center justify-center"
                        disabled={isSubmitting}
                        style={{ minWidth: '150px' }} // Set a minimum width to prevent resizing
                    >
                        {isSubmitting ? (
                            <>
                                <svg
                                    className="animate-spin h-5 w-5 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                                    />
                                </svg>
                                Creating draft...
                            </>
                        ) : (
                            "New Report"
                        )}
                    </button>


                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Date
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Deck ID
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Attachments
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {sortedDecks.map((deck) => (
                                    <tr key={deck.deck_id}>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatDate(deck.date)}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 underline"><Link to={`/create/${deck.deck_id}`}>{deck.deck_id}</Link></td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{deck.uploaded_files.length}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getDeckStatus(deck)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Navigation from './Navigation.js'
import makeApiRequest from './util/diffute.js';
import { extractURLs, validateURLs } from './util/Urls.js';
import Steps from './components/Steps.js'
import Templates from './components/Templates.js'
import SpeakersNotes from './components/SpeakersNotes.js'
import Confirmation from './components/ConfirmationComponent.js'
import File from './components/File.js'
import ApiError from './errors/pptx';

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'

export default function NewPresentation() {
    const navigate = useNavigate(); // Get the navigate function
    const { deck_id } = useParams();
    const textareaRef = useRef(null);
    const location = useLocation();

    const [placeholder, setPlaceholder] = useState('drag and drop PDFs, Word Docs and enter instructions for the plagiarism check in the language you want for your report output.');
    const [minCharCount, setMinCharCount] = useState(300);
    const [maxCharCount, setMaxCharCount] = useState(2500);

    const calculateCharacterCount = (text) => {
        // Check if text is undefined
        if (text === undefined) {
            return 0;
        }

        // Remove whitespace with regex and count characters
        return text.replace(/\s/g, '').length;
    };


    const initialFormData = {
        description: '',
        complete_notes: false,
        template: 'Minimalist',
        speaker_name: '',
        customProfile: ''
    };

    const [charCount, setCharCount] = useState(0);

    const [completeNotes, setCompleteNotes] = useState(false); // Added state for selected template
    const [currentStep, setCurrentStep] = useState(1); // Added this state to manage steps

    const [formData, setFormData] = useState(initialFormData);
    const [description, setDescription] = useState(initialFormData.description); // Initialize from formData
    const [selectedTemplateName, setSelectedTemplateName] = useState(initialFormData.template); // Initialize from formData
    const [selectedSpeakerName, setSelectedSpeakerName] = useState(initialFormData.speaker_name); // Initialize from formData
    const [customProfile, setCustomProfile] = useState(initialFormData.customProfile || ''); // Initialize customProfile state with an empty string
    const [isSubmitting, setIsSubmitting] = useState(false);  // New state variable
    const [urlList, setUrlList] = useState([]);  // New state variable to store the URLs
    const [disableMinCharCheck, setDisableMinCharCheck] = useState(false);


    const [media, setMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // useEffect(() => {

    //     if (location.state && location.state.formData) {
    //         console.log("Setting formData to: ", location.state.formData);
    //         setFormData(location.state.formData);
    //     }
    // }, []);

    // This will run every time the component reloads (i.e., re-renders)

    // useEffect(() => {
    //     console.log("Updated formData:", formData);
    // }, [formData]);

    useEffect(() => {
        checkAndSetRequiredAttribute();
    });

    const debounceTimeout = useRef(null);

    useEffect(() => {
        // Create a function to send formData to the server
        const sendDataToServer = async () => {
            try {

                const csrfResponse = await makeApiRequest('csrf', null, 'GET');
                const csrfToken = csrfResponse.csrf_token;

                // Prepare headers with CSRF token
                const extraHeaders = {
                    'x-csrf-token': csrfToken
                };


                // Make a network request to send formData to the server (replace with your own API endpoint and method)
                formData.deck_id = deck_id;
                const responseData = await makeApiRequest(`syncdeck/${deck_id}`, navigate, 'PUT', formData, false, 4, {}, extraHeaders);

                // Handle the response as needed (e.g., check for success, handle errors)
                if (responseData && responseData.deck) {
                    // Data successfully synced with the server
                } else {
                    // Handle errors here
                    console.error('Server error:', responseData);
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    console.error(`Error ${error.status}:`, error.message);
                    // Optionally, handle different error statuses differently
                } else {
                    // Handle network errors
                    console.error('Network error:', error);
                }
            }
        };

        // Use a debounce function to delay the execution of sendDataToServer
        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            sendDataToServer();
        }, 1000); // Adjust the debounce delay as needed (e.g., 1000ms for 1 second)

        // Cleanup: Clear the timeout when the component unmounts
        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [formData, deck_id]);


    // This will run every time uploadedFiles changes
    useEffect(() => {
        checkAndSetRequiredAttribute();
    }, [uploadedFiles]);

    // Extracted the logic to its own function to avoid duplication
    function checkAndSetRequiredAttribute() {
        if (uploadedFiles.length > 0) {
            setMinCharCount(40);
            setPlaceholder("What do you want the AI to do with these documents? Use the desired spoken language which will be used in the report generated.");
        } else {
            if (textareaRef.current) {
                textareaRef.current.required = true;  // set textarea to required
            }
            setPlaceholder('Drag and drop PDFs, Word Docs and enter instructions for the plagiarism check in the language you want for your report output.');
        }
    }

    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchData = async () => {
            try {

                const csrfResponse = await makeApiRequest('csrf', null, 'GET');
                const csrfToken = csrfResponse.csrf_token;

                // Prepare headers with CSRF token
                const extraHeaders = {
                    'x-csrf-token': csrfToken
                };

                const responseData = await makeApiRequest(`createstub/${deck_id}`, navigate, 'GET', null, false, 4, {}, extraHeaders);
                // Update the formData state with data from the server
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    description: responseData.deck.description, // Update specific attributes from responseData
                    template: responseData.deck.pptx_template,
                    speaker_name: responseData.deck.speaker_name
                }));

                setUploadedFiles((prevFiles) => {
                    const newFilesSet = new Set([...prevFiles, ...(responseData.deck.uploaded_files || [])]);
                    return [...newFilesSet];
                });
            } catch (error) {
                if (error instanceof ApiError) {
                    console.error(`Error ${error.status}:`, error.message);
                    alert(`Error fetching data for deck ${deck_id}: ${error.message}`);
                } else {
                    console.error('Unexpected error:', error);
                    alert(`Error fetching data for deck ${deck_id}: An unexpected error occurred`);
                }
            }
        };

        // Call the async function
        fetchData();
    }, [deck_id]); // This useEffect will run whenever deck_id changes


    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            template: selectedTemplateName,
        }));
    }, [selectedTemplateName]);

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            speaker_name: selectedSpeakerName,
        }));
    }, [selectedSpeakerName]);

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            customProfile: customProfile,
        }));
    }, [customProfile]);

    useEffect(() => {
        document.title = "Let AI check for plagiarism";
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute('content', 'Create a plagiarism report with AI'); // Set the page description
        }
    }, []);

    useEffect(() => {
    }, [disableMinCharCheck]);

    useEffect(() => {
        // Calculate initial character count without whitespace
        const initialCharCount = calculateCharacterCount(formData.description);
        setCharCount(initialCharCount);

        // Trigger auto-grow on page load and when currentStep changes
        autoGrowTextarea();
    }, [formData.description, currentStep]); // Added currentStep as a dependency

    useEffect(() => {
        const handleDragOverGlobal = (e) => {
            console.log('Drag Over');
            e.preventDefault();
            setIsDragOver(true);
        };

        const handleDropGlobal = (e) => {
            console.log('Drop');
            e.preventDefault();
            handleDrop(e);  // Call your handleDrop function here
            setIsDragOver(false);
        };


        const handleDragLeaveGlobal = (e) => {
            setIsDragOver(false);
        };

        document.addEventListener('dragover', handleDragOverGlobal);
        document.addEventListener('drop', handleDropGlobal);
        document.addEventListener('dragleave', handleDragLeaveGlobal);

        return () => {
            document.removeEventListener('dragover', handleDragOverGlobal);
            document.removeEventListener('drop', handleDropGlobal);
            document.removeEventListener('dragleave', handleDragLeaveGlobal);
        };
    }, []);


    const autoGrowTextarea = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    };

    const handleFileDelete = (fileToDelete) => {
        setUploadedFiles(prevFiles => prevFiles.filter(file => file !== fileToDelete));
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragOver(false);
        setIsUploading(true);

        let files = [...e.dataTransfer.files];

        // Filter out non-image files
        files = files.filter(file => {
            const fileType = file.type;
            // Check if the file type starts with 'image/', 'audio/', or 'video/'
            return !fileType.startsWith('image/') && !fileType.startsWith('audio/') && !fileType.startsWith('video/');
        });

        if (files.length === 0) {
            alert("That file type is not supported for upload.");
            setError("That file type is not supported for upload.");
            return;
        }

        try {

            const csrfResponse = await makeApiRequest('csrf', null, 'GET');
            const csrfToken = csrfResponse.csrf_token;

            // Prepare headers with CSRF token
            const extraHeaders = {
                'x-csrf-token': csrfToken
            };

            const formData = new FormData();

            // Append each file to the formData
            for (let file of files) {
                formData.append('files', file);
            }

            // Make a single API request with all the files
            const responseData = await makeApiRequest(deck_id + `/files`, navigate, 'POST', formData, true, 4, {}, extraHeaders);

            if (responseData && responseData.deck && responseData.deck.uploaded_files) {
                setUploadedFiles(prevFiles => {
                    const newFilesSet = new Set([...prevFiles, ...responseData.deck.uploaded_files]);
                    return [...newFilesSet];
                });

                setError(null);
            } else {
                alert(error.message);
                throw new Error('Invalid server response');
            }
        } catch (error) {
            if (error instanceof ApiError) {
                console.error(`Error ${error.status}:`, error.message);
                setError(error.message);
                alert(error.message);
            } else {
                console.error('Network error:', error);
                setError('Network error occurred');
            }
        }


        setIsUploading(false);
    }


    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        autoGrowTextarea(); // Auto-grow the textarea
        setCharCount(calculateCharacterCount(value)); // Update character count
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        if (currentStep === 1) {
            // Validate that the description is between 300 and 500 characters
            // if (charCount < 300 || charCount > 500) {
            //     alert("Please enter a description between 300 and 500 characters.");
            //     setIsSubmitting(false);
            //     return;
            // }

            // Validate URLs before proceeding to the next steps
            let urls = [];
            if (formData.description && formData.description.length > 0) {
                setDescription(formData.description);
                // console.log("called setDescription with: ", formData.description);
                const areUrlsValid = await validateURLs(formData, setDisableMinCharCheck, setUrlList);

                if (!areUrlsValid) {
                    setIsSubmitting(false);
                    return;
                }
                urls = extractURLs(formData.description);
            }

            if (charCount < minCharCount || charCount > maxCharCount) {
                alert("Please enter a description between " + minCharCount + " and " + maxCharCount + " characters in the spoken language you want for your presentation.");
                setIsSubmitting(false);
                return;
            }

            setCurrentStep(2); // Move to Templates
        } else if (currentStep === 2) {
            // Validate that a template is selected
            if (!selectedTemplateName) {
                alert("Please select a template.");
                setIsSubmitting(false);
                return;
            }
            setCurrentStep(3); // Move to Confirmation
        } else if (currentStep === 3) {
            // Validate that a template is selected
            if (!selectedTemplateName) {
                alert("Please select a template.");
                setIsSubmitting(false);
                return;
            }
            setCurrentStep(4); // Move to Confirmation
        } else {
            // Make the API call
            try {
                formData.pptx_template = selectedTemplateName;
                formData.deck_id = deck_id;

                if (formData.description && formData.description.length > 0) {
                    formData.urls = extractURLs(formData.description);
                } else {
                    formData.urls = [];
                }

                if (selectedSpeakerName && selectedSpeakerName.length > 0) {
                    if (selectedSpeakerName === "No Speakers Notes") {
                        formData.complete_notes = false;
                        formData.speaker_name = "";
                    } else {
                        formData.complete_notes = true;
                        formData.speaker_name = selectedSpeakerName;
                    }
                } else if (customProfile && customProfile.length > 0) {
                    formData.complete_notes = true;
                    formData.speaker_name = customProfile;
                } else {
                    formData.complete_notes = false;
                    formData.speaker_name = "";
                }

                if (uploadedFiles.length > 0 || formData.urls.length > 0) {
                    if (!formData.description) {
                        formData.description = "";
                    }
                }

                const csrfResponse = await makeApiRequest('csrf', null, 'GET');
                const csrfToken = csrfResponse.csrf_token;

                // Prepare headers with CSRF token
                const extraHeaders = {
                    'x-csrf-token': csrfToken
                };

                const responseData = await makeApiRequest('createnew/' + deck_id, navigate, 'POST', formData, false, 4, {}, extraHeaders);
                navigate(`/checkout/` + responseData.deck.deck_id, { state: { formData: formData } });

            } catch (error) {
                if (error instanceof ApiError) {
                    const status = error.status;
                    let errorMessage = error.message || 'Request Error';

                    if (status === 401 || status === 403) {
                        errorMessage = status === 401 ? 'Unauthorized access' : 'Forbidden access';
                    } else if (status === 422) {
                        errorMessage = 'Request Error';
                    }

                    alert(errorMessage);
                    console.error(`Error ${status}:`, errorMessage);
                } else {
                    alert('An unexpected error occurred');
                    console.error('Unexpected error:', error);
                }
            }

        }
        setIsSubmitting(false);
        // if (currentStep === 3) {
        //     // Clear formData from localStorage when form is successfully submitted
        //     // localStorage.removeItem('formData');
        // }
    };

    const handleGoBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            setIsSubmitting(false);  // Reset isSubmitting when going back
        }
    };

    return (

        <div>
            <Navigation />
            {isDragOver && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'RGBA(0, 0, 200, 0.1)', // semi-transparent blue
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000 // ensure it's on top of everything else
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgba(37, 99, 235, 1)" className="w-24 h-24">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                    </svg>

                </div>
            )}

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                <div className="mx-auto max-w-3xl">
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-12">
                                    <Steps currentStep={currentStep} />
                                    <div className="mt-6 flex items-center justify-between gap-x-6">
                                        {currentStep > 1 ? (
                                            <button
                                                type="button"
                                                className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                onClick={handleGoBack}
                                            >
                                                Previous step
                                            </button>
                                        ) : (
                                            <div></div>
                                        )}
                                        <button
                                            type="submit"
                                            className={`inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white ${isSubmitting || isUploading || isDeleting
                                                ? 'bg-blue-500 cursor-not-allowed'
                                                : 'bg-blue-600 hover:bg-blue-500'
                                                } transition ease-in-out duration-150`}
                                            disabled={isSubmitting || isUploading || isDeleting}
                                        >
                                            {
                                                isSubmitting ? (
                                                    <>
                                                        <svg
                                                            className="animate-spin h-5 w-5 mr-3 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                                                            />
                                                        </svg>
                                                        Submitting...
                                                    </>
                                                ) : isUploading ? (
                                                    <>
                                                        <svg
                                                            className="animate-spin h-5 w-5 mr-3 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                                                            />
                                                        </svg>
                                                        Uploading...
                                                    </>
                                                ) : isDeleting ? (
                                                    <>
                                                        <svg
                                                            className="animate-spin h-5 w-5 mr-3 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                                                            />
                                                        </svg>
                                                        Deleting...
                                                    </>

                                                ) : (
                                                    currentStep < 2 ? 'Next step' : 'Create Report!'
                                                )
                                            }
                                        </button>


                                    </div>


                                    {/* Step 1: Presentation Subject */}
                                    {currentStep === 1 && (
                                        <div style={{ position: 'relative' }}>
                                            <textarea
                                                name="description"
                                                id="description"
                                                placeholder={placeholder}
                                                required
                                                value={formData.description}
                                                onChange={handleInputChange}
                                                // onDrop={handleDrop}
                                                // onDragOver={handleDragOver}
                                                // onDragLeave={handleDragLeave}
                                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 
                                                    ${isDragOver ? 'border-blue-500' : ''} 
                                                    ${isUploading || isDeleting ? 'pulsate-border' : ''}
                                                `}
                                                ref={textareaRef}
                                                // style={{ marginTop: '50px', fontSize: '18px' }}
                                                rows="5"
                                                style={{
                                                    animation: `${isUploading || isDeleting ? 'pulsate 1s infinite' : 'none'}`,
                                                    marginTop: '50px',
                                                    fontSize: '18px',
                                                    borderColor: 'blue',
                                                    borderWidth: '2px'
                                                }}
                                            ></textarea>


                                            {/* Render the File component */}
                                            <div className="text-sm text-gray-400 mb-4">{charCount} characters (PDFs, Word Docs, Text Docs --- or min {minCharCount}, max {maxCharCount})</div> {/* Added character counter */}
                                            <div className="mt-2">
                                                {uploadedFiles.map((file, index) => (
                                                    <File onDelete={handleFileDelete} key={index} deckId={deck_id} file={file} style={{ position: 'absolute', top: '50px', left: '10px' }} />
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {/* Step 4: Confirmation */}
                                    {currentStep === 2 && (
                                        <div>
                                            <Confirmation customProfile={customProfile} completeNotes={formData.complete_notes} selectedSpeakerName={selectedSpeakerName} selectedTemplateName={selectedTemplateName} description={formData.description} deck_status="Waiting for user press Create Report!" />
                                        </div>
                                    )}
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div >

        </div >



    )
}
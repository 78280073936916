import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { PlusIcon } from '@heroicons/react/20/solid'

import Navigation from './Navigation.js'
import ListOfDecks from './components/ListOfDecks.js'

import makeApiRequest from './util/diffute.js';
import ApiError from './errors/pptx';

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'


const SkeletonRow = () => (
    <tr>
        <td className="whitespace-nowrap px-3 py-4">
            <div className="h-4 bg-gray-300 rounded w-full"></div>
        </td>
        <td className="whitespace-nowrap px-3 py-4">
            <div className="h-4 bg-gray-300 rounded w-full"></div>
        </td>
        <td className="whitespace-nowrap px-3 py-4">
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        </td>
        <td className="whitespace-nowrap px-3 py-4">
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        </td>
    </tr>
);


const SkeletonTable = () => (
    <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
                <h1 className="mt-8 text-base font-semibold leading-6 text-gray-900">Plagiarism Reports</h1>
            </div>
            <div className="mt-8 sm:ml-16 sm:mt-0 sm:flex-none">
                <div className="block rounded-md bg-gray-300 px-3 py-2 text-center text-sm font-semibold shadow-sm" style={{ minWidth: '150px' }}></div> {/* Skeleton for button */}
            </div>
        </div>
        <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Deck ID</th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Attachments</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"><span className="sr-only">View</span></th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
);


export default function NewDeckID() {
    const navigate = useNavigate(); // Get the navigate function
    const [isSubmitting, setIsSubmitting] = useState(false);  // New state variable
    const [decks, setDecks] = useState([]);  // New state variable
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        document.title = "Let AI check for plagiarism";
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute('content', 'Create a slide deck with AI'); // Set the page description
        }
    }, []);

    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const responseData = await makeApiRequest(`profile`, navigate, 'GET');
                setDecks(responseData.user.decks);
            } catch (error) {
                if (error instanceof ApiError) {
                    console.error(`Error ${error.status}:`, error.message);
                } else {
                    console.error('Unexpected error:', error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        // Call the async function
        fetchData();
    }, []);  // Empty dependency array means this useEffect runs once when the component mounts

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {

            const csrfResponse = await makeApiRequest('csrf', null, 'GET');
            const csrfToken = csrfResponse.csrf_token;

            // Prepare headers with CSRF token
            const extraHeaders = {
                'x-csrf-token': csrfToken
            };

            const responseData = await makeApiRequest('createstub', navigate, 'POST', null, false, 4, {}, extraHeaders);
            if (responseData && responseData.deck && responseData.deck.deck_id) {
                navigate(`/create/${responseData.deck.deck_id}`);
            } else {
                throw new ApiError('Invalid response format or missing deck_id', 500);
            }
        } catch (error) {
            if (error instanceof ApiError) {
                const status = error.status;
                if (status >= 400 && status < 500) {
                    console.error(`Client error ${status}:`, error.message);
                } else if (status >= 500) {
                    console.error(`Server error ${status}:`, error.message);
                } else {
                    console.error('An unexpected error occurred:', error.message);
                }
            } else {
                console.error('Unexpected error:', error);
            }
            window.alert("Error occurred while creating presentation. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };




    return (
        <div>
            <Navigation />
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">

                    {isLoading ? (
                        <SkeletonTable />
                    ) :
                        decks.length > 0 ? (
                            <ListOfDecks handleSubmit={handleSubmit} decks={decks} />
                        ) : (
                            <div className='EmptyTable'>
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-12">
                                        <div className="text-center">
                                            <button
                                                onClick={handleSubmit} // Step 2: Attach the onClick event handler
                                                disabled={isSubmitting} // Ensure the button is disabled when the form is submitting
                                                className="mx-auto text-gray-400 bg-white border-0" // Step 3: Optional styling
                                                aria-label="Create a new presentation" // Accessible label for the button
                                            >
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        vectorEffect="non-scaling-stroke"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                    />
                                                </svg>
                                            </button>
                                            <p className="mt-1 text-sm text-gray-500">Create a new presentation.</p>
                                            <div className="mt-6">
                                                <div className="flex justify-center items-center">
                                                    <button
                                                        onClick={handleSubmit}
                                                        type="button"
                                                        className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex items-center justify-center"
                                                        disabled={isSubmitting}
                                                        style={{ minWidth: '150px' }} // Set a minimum width to prevent resizing
                                                    >
                                                        {isSubmitting ? (
                                                            <>
                                                                <svg
                                                                    className="animate-spin h-5 w-5 mr-3"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                                                                    />
                                                                </svg>
                                                                Creating draft...
                                                            </>
                                                        ) : (
                                                            "New Report"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}

                </div>
            </div>
        </div>
    )

}
import React, { useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import Logo from './Logo.js'
import { useNavigate } from 'react-router-dom';

export default function ForgotPasswordSuccess() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(function () {
            navigate('/create');
        }, 3000);
    }, []);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Logo className="logo" stroke="rgba(37, 99, 235, 1)" width={12} height={12} />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-600">
                    Done!
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <a href="/signin">
                                <h3 className="text-sm font-medium text-blue-800">We reset your password!</h3>
                                <div className="mt-2 text-sm text-blue-700">
                                    <p>We'll redirect you in a few seconds to create a presentation.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import makeApiRequest from '../util/diffute';  // Importing makeApiRequest
import ApiError from '../errors/pptx';
import { useNavigate } from 'react-router-dom';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function File({ deckId, file, onDelete }) {
    const navigate = useNavigate();
    const parts = file.split("/"); // Split the string by "/"
    const fileName = parts[parts.length - 1]; // Get the last element

    const handleRemove = async () => {
        const shouldDelete = window.confirm("Are you sure you want to remove this file?");
        if (!shouldDelete) return;

        try {

            const csrfResponse = await makeApiRequest('csrf', null, 'GET');
            const csrfToken = csrfResponse.csrf_token;

            // Prepare headers with CSRF token
            const extraHeaders = {
                'x-csrf-token': csrfToken
            };


            const data = {
                object_key_to_remove: file
            };
            await makeApiRequest(`${deckId}/files`, navigate, 'DELETE', data, false, 4, {}, extraHeaders);
            // Optionally, update the UI to reflect the deletion
            if (onDelete) {
                onDelete(file);
            }
        } catch (error) {
            if (error instanceof ApiError) {
                console.error(`Error ${error.status}:`, error.message);
                // Optionally, display an error to the user with error.message
            } else {
                console.error("Unexpected error:", error);
                // Optionally, display a generic error message to the user
            }
        }
    };




    return (
        <Menu as="div" className="relative inline-block text-left border border-blue-600 border-2 rounded-md">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {fileName}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#"
                                    onClick={handleRemove}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                    )}
                                >
                                    Remove
                                </a>
                            )}
                        </Menu.Item>


                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

import makeApiRequest from './diffute.js';
import ApiError from '../errors/pptx';

const extractURLs = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex) || [];
};

const validateURLs = async (formData, setDisableMinCharCheck, setUrlList) => {
    const urls = extractURLs(formData.description);
    let areUrlsValid = false;

    if (urls.length === 0) {
        return true;
    }

    try {
        const results = await makeApiRequest('check-url', 'POST', { urls });
        let errorMessage = '';

        const inaccessibleUrls = results.filter(result => !result.is_accessible).map(result => result.url);
        const unsupportedDocs = results.filter(result => !result.is_supported_doc).map(result => result.url);

        if (inaccessibleUrls.length > 0) {
            errorMessage += `The following URLs are not accessible: ${inaccessibleUrls.join(', ')}\n`;
        }
        if (unsupportedDocs.length > 0) {
            errorMessage += `The following URLs are not linked to supported documents: ${unsupportedDocs.join(', ')}`;
        }

        if (errorMessage === '') {
            areUrlsValid = true;
            setDisableMinCharCheck(true);
            const validUrls = results.filter(result => result.is_accessible && result.is_supported_doc).map(result => result.url);
            setUrlList(validUrls);
        } else {
            alert(errorMessage);
            setDisableMinCharCheck(false);
        }
    } catch (error) {
        if (error instanceof ApiError) {
            console.error(`Error ${error.status}:`, error.message);
            alert(`Error validating URLs: ${error.message}`);
        } else {
            console.error('Unexpected error:', error);
            alert(`Error validating URLs: An unexpected error occurred`);
        }
    }

    return areUrlsValid;
};



export { extractURLs, validateURLs };

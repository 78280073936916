import React from 'react';
import metropolitanImage from '../images/templates/metropolitan.jpg';
import minimalistImage from '../images/templates/minimalist.jpg';
import spaceImage from '../images/templates/space.jpg';
import mountainImage from '../images/templates/mountain.jpg';
import modernistImage from '../images/templates/modernist.jpg';
import flowImage from '../images/templates/financial.jpg';
import gradientImage from '../images/templates/gradient.jpg';
import shapesImage from '../images/templates/shapes.jpg';
// import beachImage from '../images/templates/beach.jpg';
// import villageImage from '../images/templates/village.jpg';

const jsonData = [
  {
    "descriptors": "",
    "id": 6,
    "priority": 6,
    "template": "Flow",
    "description": "A template that's easy on the eyes and flows as eloquently as the content.",
    "thumbnail": flowImage
  },
  {
    "descriptors": "",
    "id": 2,
    "priority": 2,
    "template": "Minimalist",
    "description": "A clean and simple design with minimal elements and distractions.",
    "thumbnail": minimalistImage
  },
  {
    "descriptors": "",
    "id": 3,
    "priority": 3,
    "template": "Space",
    "description": "An outer space-themed design with celestial imagery and elements.",
    "thumbnail": spaceImage
  },
  {
    "descriptors": "",
    "id": 5,
    "priority": 5,
    "template": "Modernist",
    "description": "A design inspired by modernist principles with a focus on simplicity and functionality.",
    "thumbnail": modernistImage
  },
  {
    "descriptors": "",
    "id": 7,
    "priority": 7,
    "template": "Gradient",
    "description": "A design featuring smooth color gradients for a visually appealing backdrop.",
    "thumbnail": gradientImage
  },
  {
    "descriptors": "",
    "id": 1,
    "priority": 1,
    "template": "Metro",
    "description": "Styled like a metropolitan transit map such as the Boston MBTA",
    "thumbnail": metropolitanImage
  },
  {
    "descriptors": "",
    "id": 8,
    "priority": 8,
    "template": "Shapes",
    "description": "A template adorned with geometric shapes and patterns for a unique visual style.",
    "thumbnail": shapesImage
  },
  // {
  //   "descriptors": "",
  //   "id": 9,
  //   "priority": 9,
  //   "template": "Beach",
  //   "description": "A beach-themed template with seaside imagery and a tranquil atmosphere.",
  //   "thumbnail": beachImage
  // },
  // {
  //   "descriptors": "",
  //   "id": 10,
  //   "priority": 10,
  //   "template": "Village",
  //   "description": "A template featuring a picturesque village setting with a charming and rustic feel.",
  //   "thumbnail": villageImage
  // }
];

export default jsonData;


import React, { useEffect, useState } from 'react';
import Error from './Error';
import Logo from './Logo.js'
import makeApiRequest from './util/diffute';  // Importing makeApiRequest
import ApiError from './errors/pptx';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ResetPassword() {

  const navigate = useNavigate();
  const { reset_token } = useParams();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    document.title = "Sign up to Use Artificial Intelligence to check for Plagiarism";
    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute('content', 'Sign up to use Artificial Intelligence to check for Plagiarism'); // Set the page description
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
    hp: '' // Add 'hp' field to the initial state
  });
  const [hp, setHp] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Fetch the CSRF token first
      const csrfResponse = await makeApiRequest('csrf', navigate, 'GET');
      const csrfToken = csrfResponse.csrf_token;

      // Prepare headers with CSRF token
      const extraHeaders = {
        'x-csrf-token': csrfToken
      };

      const endpoint = `passwordresetnew/${reset_token}`;  // Your actual endpoint with token
      const method = 'POST';
      // Include extraHeaders in the request
      const response = await makeApiRequest(endpoint, navigate, method, formData, false, 4, {}, extraHeaders);

      if (response) {
        // Handle success, such as redirecting to a success page
        window.location.href = '/passwordreset/success';
      } else {
        throw new ApiError('Something went wrong', 500);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        const status = error.status;
        if (status >= 400 && status < 500) {
          console.error(`Client error ${status}:`, error.message);
        } else if (status >= 500) {
          console.error(`Server error ${status}:`, error.message);
        } else {
          console.error('An unexpected error occurred:', error.message);
        }
      } else {
        console.error('Unexpected error:', error);
      }
      setError(error.message || 'Request failed');
    } finally {
      setLoading(false);
    }
  };



  const handleHpChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hp: e.target.value
    }));
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center items-center h-10">
            <Logo className="logo" stroke="rgba(37, 99, 235, 1)" width={12} height={12} />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-500">
            Enter a new password for stole.ai
          </h2>
          {error && <Error error={error} />}

        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password2" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="password2"
                  name="password2"
                  type="password"
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <input
              type="hidden"
              name="hp"
              value={formData.hp} // Use formData.hp as the value
              onChange={handleHpChange} // Update the 'hp' value in formData
            />


            <div>

              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-blue-500 bg-opacity-88 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                {loading ? (
                  <>
                    <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z" />
                    </svg>
                    Resetting password...
                  </>
                ) : (
                  'Reset it!'
                )}
              </button>
            </div>
            <p className="mt-10 text-center text-sm text-gray-500">
              Remember your password?{' '}
              <a href="/signin" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Sign in here!
              </a>
            </p>

          </form>
        </div>
      </div>
    </>
  )
}

import { PaperClipIcon } from '@heroicons/react/20/solid'
import templatesData from './TemplatesData';
import speakersData from './SpeakersData';

import Cookies from 'js-cookie';

function toTitleCase(str) {
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

function extractFilenames(paths) {
    // Use map to transform each path into just its filename
    const filenames = paths.map(path => {
        // Split the path by '/' to separate directories and filename
        const parts = path.split('/');
        // Get the last part of the array, which should be the filename
        return parts[parts.length - 1];
    });

    // Join the filenames together with commas
    return filenames.join(', ');
}

export default function Confirmation({ urls, uploadedFiles, customProfile, completeNotes, selectedTemplateName, selectedSpeakerName, description, deck_status, email_address, credits, premiumCredits }) {

    const selectedTemplate = templatesData.find(template => template.template === selectedTemplateName);
    const selectedSpeaker = speakersData.find(template => template.template === selectedSpeakerName);

    // Read the "userProfile" cookie
    const userProfileData = localStorage.getItem('userProfile');
    let emailAddress = "";
    // Check if the cookie exists
    if (userProfileData) {
        // Parse the JSON data from the cookie
        try {
            const userProfile = JSON.parse(userProfileData);

            // Now you can access values from the userProfile object
            emailAddress = userProfile.email_address;

            if (email_address && email_address.length > 0) {
                emailAddress = email_address;
            }

        } catch (error) {
            // Handle any parsing errors
            console.error('Error parsing userProfile cookie:', error);
        }
    } else {
        // Handle the case where the "userProfile" cookie doesn't exist
        console.log('User Profile cookie not found.');
    }


    return (
        <div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">

                    {(credits !== undefined && credits !== -1000) ? (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Regular credits remaining</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{credits}</dd>
                        </div>
                    ) : null}


                    {(premiumCredits !== undefined) ? (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Premium credits remaining</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{premiumCredits}</dd>
                        </div>
                    ) : null}

                    {(deck_status === "processing" || deck_status === "preparing") ? (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-black">Next steps</dt>
                            <dd className="mt-1 text-sm leading-6 text-black sm:col-span-2 sm:mt-0">We've started generating your report immediately and will send you an email with a link to the report when completed.</dd>
                        </div>
                    ) : null}

                    {description && description.length > 0 && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Report instructions</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{description}</dd>
                        </div>
                    )}

                    {uploadedFiles && uploadedFiles.length > 0 && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Uploaded Files</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{extractFilenames(uploadedFiles)}</dd>
                        </div>
                    )}

                    {urls && urls.length > 0 && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">URLs</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{urls}</dd>
                        </div>
                    )}


                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Report status</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{deck_status}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Will send email with report</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{emailAddress}</dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Logo from './Logo.js'

import Error from './Error';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import makeApiRequest from './util/diffute';  // Importing makeApiRequest
import ApiError from './errors/pptx';
import { useNavigate } from 'react-router-dom';

export default function InvitationActivation() {
  const navigate = useNavigate();
  const { activation_key } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [invitationCodeValid, setInvitationCodeValid] = useState(false);
  const [hp, setHp] = useState('');

  const handleHpChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hp: e.target.value
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
    hp: '' // Add 'hp' field to the initial state
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = 'invitation/' + activation_key;
      const method = 'POST';
      const response = await makeApiRequest(endpoint, navigate, method, formData);

      if (response) {
        // Handle success, like redirecting to a thank-you page or showing a success message
        window.location.href = '/invitation/success';
      } else {
        throw new ApiError('Something went wrong', 500);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setError(error.message || 'Request failed');
      } else {
        setError('An unexpected error occurred');
      }
    }
  };


  useEffect(() => {
    const activateAccount = async () => {
      try {
        const endpoint = `invitation/${activation_key}`;
        const response = await makeApiRequest(endpoint, navigate);
        if (response) {
          setInvitationCodeValid(true);
        } else {
          throw new ApiError('Something went wrong', 500);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setError(error.message || 'Request failed');
        } else {
          setError('An unexpected error occurred');
        }
      }
    };

    activateAccount();
  }, [activation_key]);



  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {error && <Error error={error} />}
        {invitationCodeValid && (
          <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex justify-center items-center h-10">
                  <Logo className="logo" stroke="rgba(37, 99, 235, 1)" width={12} height={12} />
                </div>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-500">
                  Set a password for stole.ai
                </h2>
              </div>

              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password2" className="block text-sm font-medium leading-6 text-gray-900">
                      Confirm Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password2"
                        name="password2"
                        type="password"
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <input
                    type="hidden"
                    name="hp"
                    value={formData.hp} // Use formData.hp as the value
                    onChange={handleHpChange} // Update the 'hp' value in formData
                  />


                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-blue-500 bg-opacity-88 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Set it!
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </>
        )}
        {success && (
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-800">Activation successful!</h3>
                <div className="mt-2 text-sm text-blue-700">
                  <a href="/signin">
                    <p>Your account has been activated.  Click here to sign in.</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { useEffect } from 'react';

const Signout = () => {
  useEffect(() => {

    let domain;
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      console.log("Running on localhost");
      domain = 'localhost';
    } else {
      // Running with a public IP address or domain
      console.log("Running on AWS");
      domain = '.stole.ai'; // Replace "example.com" with your publicly accessible domain or IP address
    }

    document.cookie = `a=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
    document.cookie = `u=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
    localStorage.removeItem('userProfile');
    localStorage.removeItem('formData');
    // Redirect to '/'
    window.location.href = '/';
  }, []);

  return null; // Or you can return a loading indicator or a message briefly before redirecting
};

export default Signout;

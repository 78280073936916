import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import {
    ArrowPathIcon,
    Bars3Icon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import Logo from '../Logo.js'
import isAuthenticated from '../util/auth.js';

export default function Header({ textColor }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigation = [
        { name: 'Pricing', href: '/pricing' },
        { name: 'Blog', href: '/blog' },
        // { name: 'Blog', href: '/blog' },
        // { name: 'Gallery', href: '/gallery' },
        { name: 'FAQ', href: '/faq' },
        // { name: 'Examples', href: '/examples' },
    ]

    // Define your 'navigation' and 'isAuthenticated' variables here if they are not already defined

    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">stole.ai</span>
                        <Logo className="logo" stroke="rgba(255, 255, 255, 1)" width={12} height={12} />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-${textColor}`}
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href} className={`text-sm font-semibold leading-6 text-${textColor}`}>
                            {item.name}
                        </a>
                    ))}
                    {isAuthenticated() ? (
                        <a key="createSlides" href="/create" className={`text-sm font-semibold leading-6 text-${textColor}`}>
                            Create Report
                        </a>
                    ) : (
                        <a key="signIn" href="/signin" className={`text-sm font-semibold leading-6 text-${textColor}`}>
                            Sign in
                        </a>
                    )}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <div>
                        {isAuthenticated() ? (
                            <a href="/create" className={`text-sm font-semibold leading-6 text-${textColor}`}>
                                Start here <span aria-hidden="true">&rarr;</span>
                            </a>
                        ) : (
                            <a href="/signin" className={`text-sm font-semibold leading-6 text-${textColor}`}>
                                Sign in <span aria-hidden="true">&rarr;</span>
                            </a>
                        )}
                    </div>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-blue-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">stole.ai</span>
                            <Logo className="logo" stroke="rgba(37, 99, 235, 1)" width={12} height={12} />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-blue-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-blue-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue-900 hover:bg-blue-50"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                                {isAuthenticated() ? (
                                    <a key="createSlides" href="/create" className="text-sm font-semibold leading-6 text-blue-600">
                                        Create Report
                                    </a>
                                ) : (
                                    <a key="signIn" href="/signin" className="text-sm font-semibold leading-6 text-blue-600">
                                        Sign in
                                    </a>
                                )}
                            </div>
                            <div className="py-6">
                                {isAuthenticated() ? (
                                    <a href="https://stole.ai/create" className="text-sm font-semibold leading-6 text-blue-500">
                                        Start here <span aria-hidden="true">&rarr;</span>
                                    </a>
                                ) : (
                                    <a href="https://stole.ai/signin" className="text-sm font-semibold leading-6 text-blue-500">
                                        Sign in <span aria-hidden="true">&rarr;</span>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}

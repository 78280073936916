import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Signin from './Signin.js';
import ForgotPassword from './ForgotPassword.js';
import ResendActivationLink from './ResendActivationLink.js';

import PasswordReset from './PasswordReset.js';
import Signout from './Signout.js';
import Signup from './Signup';
import Activation from './Activation.js';
import Marketing from './Marketing.js';
import Blog from './Blog';
import BlogPost from './BlogPost';
import Support from './Support';
import Contact from './Contact';
import Gallery from './Gallery';
import GalleryPost from './GalleryPost';
import DeckFeedback from './DeckFeedback';
import Create from './Create.js';
import CreateDeckId from './CreateDeckId.js';
import Pricing from './Pricing.js';
import NotFound from './NotFound.js';
import Checkout from './Checkout';
import CheckoutGPT from './CheckoutGPT';
import Languages from './Languages';
import Confirmation from './Confirmation';
import SignupSuccess from './SignupSuccess';
import InvitationActivation from './InvitationActivation'
import InvitationActivationSuccess from './InvitationActivationSuccess';
import FAQ from './faq.js';

import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import ResendActivationLinkSuccess from './ResendActivationLinkSuccess'
import PasswordResetSuccess from './PasswordResetSuccess';
import ProtectedRoute from './util/ProtectedRoute';  // Import the HOC
import Cookies from 'js-cookie';

const AppRouter = () => {
  const isAuthenticated = () => {
    // Your authentication logic here
    const a = Cookies.get('a');
    return !!a;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Marketing />} />
        <Route path="/home/" element={<Navigate to="/" replace />} />
        <Route path="/home" element={<Navigate to="/" replace />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<Blog pages={window.pages} />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/gallery" element={<Gallery pages={window.pages} />} />
        <Route path="/gallery/:slug" element={<GalleryPost />} />
        <Route path="/checkout/:deck_id" element={<ProtectedRoute Component={Checkout} isAuthenticated={isAuthenticated} />} />
        <Route path="/gpt/:deck_id" element={<CheckoutGPT />} />
        <Route path="/confirmation/:deck_id" element={<Confirmation />} />
        <Route path="/feedback/:deck_id" element={<ProtectedRoute Component={DeckFeedback} isAuthenticated={isAuthenticated} />} />
        <Route path="/support" element={<ProtectedRoute Component={Support} isAuthenticated={isAuthenticated} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/create" element={<ProtectedRoute Component={CreateDeckId} isAuthenticated={isAuthenticated} />} />
        <Route path="/create/:deck_id" element={<ProtectedRoute Component={Create} isAuthenticated={isAuthenticated} />} />
        <Route path="/getpasswordlink" element={<ForgotPassword />} />
        <Route path="/getpasswordlink/success" element={<ForgotPasswordSuccess />} />
        <Route path="/resendactivationlink" element={<ResendActivationLink />} />
        <Route path="/resendactivationlink/success" element={<ResendActivationLinkSuccess />} />

        <Route path="/passwordreset/success" element={<PasswordResetSuccess />} />
        <Route path="/passwordreset/:reset_token" element={<PasswordReset />} />
        <Route path="/activate/:activation_key" element={<Activation />} exact />

        <Route path="/signin" element={<Signin />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/languages" element={<Languages />} />
        <Route path="/signup/success" element={<SignupSuccess />} />
        <Route path="/invitation/success" element={<InvitationActivationSuccess />} exact />
        <Route path="/invitation/:activation_key" element={<InvitationActivation />} exact />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

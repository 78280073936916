import { useEffect, useState } from 'react'
import dummyPost1 from './images/dummy-post.jpg'
import Header from './components/Header.js';
import Footer from './components/Footer.js';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

function extractFirstSentences(content, sentenceCount) {
    const div = document.createElement('div');
    div.innerHTML = content;

    const sentences = div.innerText.split('. '); // Assuming sentences end with periods ('.')
    const firstSentences = sentences.slice(0, sentenceCount);

    return firstSentences.join('. ') + '.';
}


export default function Blog() {

    const [pages_json, setPages_json] = useState([])
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        setPages_json(window?.pages ? window.pages : [])
    }, [])

    useEffect(() => {
        if (pages_json && pages_json.length) {
            const onlyPosts = pages_json.filter((page) => page.page_type === 'post' && page.slug_prefix === 'blog');
            setBlogs(onlyPosts);
        }
    }, [pages_json])

    useEffect(() => {
        document.title = "The stole.ai Blog";
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute('content', "A blog about using AI to check for plagiarism"); // Set the page description
        }
    }, []);

    return (
        <div>
            <div className="bg-white py-24 sm:py-32">
                <Header />
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">Articles on Detecting Plagiarism with AI</h2>
                        <p className="mt-2 text-lg leading-8 text-blue-600">
                            Use AI to check for plagiarism
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {blogs.map((post) => (
                            <a href={'/blog/' + post.slug}>
                                <article key={post.page_id} className="flex flex-col items-start justify-between">
                                    <div className="relative w-full">

                                        <img
                                            src={post.media.length ? '//' + post.media[0].link : dummyPost1}
                                            alt=""
                                            className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                        />

                                        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                    </div>
                                    <div className="max-w-xl">
                                        <div className="mt-8 flex items-center gap-x-4 text-xs">
                                            <time dateTime={post.page_modified} className="text-gray-500">
                                                {formatDate(post.page_modified)}
                                            </time>
                                        </div>
                                        <div className="group relative">
                                            <h3 className="mt-3 text-lg font-semibold leading-6 text-blue-600 group-hover:text-gray-600">
                                                <span className="absolute inset-0" />
                                                {post.title}
                                            </h3>
                                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-500" dangerouslySetInnerHTML={{ __html: extractFirstSentences(post.content, 3) }}></p>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        ))}

                    </div>
                </div>
            </div >
            <Footer />
        </div>


    )

}

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { LanguageProvider } from './util/LanguageProvider'; // Import the LanguageProvider

let Sentry;
try {
    Sentry = require("@sentry/react");
    Sentry.init({
        dsn: "https://8f62c3558a861a6b179cb0c553ca2739@o368315.ingest.sentry.io/4506537424388096",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "https://app.stole.ai"],
            }),
            new Sentry.Replay({
                networkDetailAllowUrls: ['https://app.stole.ai'],
                networkRequestHeaders: ['Origin', 'Access-Control-Request-Method', 'Access-Control-Request-Headers'],
                networkResponseHeaders: ['Access-Control-Allow-Origin', 'Access-Control-Allow-Methods', 'Access-Control-Allow-Headers', 'Access-Control-Allow-Credentials', 'Access-Control-Expose-Headers', 'Access-Control-Max-Age'],
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
} catch (error) {
    console.error("Failed to initialize Sentry:", error);
    // You could implement additional logging or error handling here if needed
}

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <LanguageProvider>
            <App />
        </LanguageProvider>
    </React.StrictMode>
);


// // Then render your application
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
